<template>
    <div>
        <v-card flat class="shadow mt-3">
            <!-- header -->
            <!-- header -->
            <div>
                <v-row align="center" class="pa-3">
                    <v-col cols="12" md="2" class="mb-sm-1">
                        <v-btn @click="openAddDialog()" color="primary" class="btn">
                            <v-icon small class="pl-1">mdi-plus</v-icon>اضافة
                        </v-btn>
                    </v-col>
                    <v-spacer />
                </v-row>
            </div>

            <!-- table -->
            <v-card flat tile>
                <v-data-table class="mytable" :items="comQus" :loading="loading" :headers="headers" :search="filter.search"
                    loading-text="جاري تحميل البيانات" no-data-text="لا توجد بيانات"
                    :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
                    <template v-slot:[`item.id`]="{ item }">
                        <h5>{{ comQus.indexOf(item) + 1 }}</h5>
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <span>{{ item.createdAt | formatDate }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div style="min-width: 75px">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="deleteItem(item.id, index)" v-on="on" x-small icon depressed>
                                        <v-icon color="error">fi fi-rr-trash</v-icon>
                                    </v-btn>
                                </template>
                                <span>حذف</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
            <v-divider></v-divider>

            <Dialog @refresh="getCommonQuestions()" />
        </v-card>
    </div>
</template>

<script>
// import Edit from "./edit.vue";
export default {
    data() {
        return {
            show: false,
            headers: [

                {
                    text: " السؤال",
                    value: "qn",
                },
                {
                    text: " الجواب",
                    value: "ansswer",
                },
                {
                    text: "تاريخ الإضافة",
                    value: "createdAt",
                },

                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            comQus: [],
            loading: false,
            uploading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            length: 0,
            filter: {
                pageIndex: 1,
                pageSize: 15,
                search: "",
                count: 0,
            },
            pageSizes: [5, 10, 15, 30, 50, 100],
        };
    },

    components: {
        Dialog: () => import("./dialog"),
        // , Edit
    },

    mounted() {
        this.getCommonQuestions();
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },
        async getCommonQuestions() {
            this.loading = true;
            try {
                let query = `CommonQuestions`;

                const res = await this.$http.get(query);
                this.comQus = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.loading = false;
            }
        },
        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.comQus.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = { dialogType: "add" };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
